
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { teamService } from '@/services/team.service';
import { Team } from '@/api-domain/team';
import { Pack } from '@/api-domain/pack';
import { packService } from '@/services/pack.service';

@Options({
  emits: ['updated', 'updatedDecks'],
})
export default class EzEditTeam extends Vue {
  @Prop({ isRequired: true }) team!: Team;

  name = '';

  emailPattern = '';

  userCount = 0;

  deckIdToAdd?: string;

  additionalDecks : string[] = [];

  subscriptionExpire: Date | null = null;

  restrictedDecks: Pack[] = [];

  showForm = false;

  isSaving = false;

  nameError = '';

  emailPatternError = '';

  userCountError = '';

  expireError = '';

  getDeckNames(): string[] {
    return this.team.additionalDecks.map((deck) => deck.name);
  }

  async onEditClick(): Promise<void> {
    this.restrictedDecks = (await packService.getAll()).filter((deck) => !deck.public);

    this.name = this.team.name;

    this.emailPattern = this.team.emailPattern;

    this.userCount = this.team.userCount;

    this.subscriptionExpire = this.team.subscriptionExpire;

    this.showForm = true;

    setTimeout(() => {
      (this.$refs.teamNameRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  async onAddDeckClick(): Promise<void> {
    if (!this.deckIdToAdd || !this.team.id) {
      return;
    }
    const deckToAdd = this.restrictedDecks.find((deck) => deck.id === this.deckIdToAdd);
    if (!deckToAdd) {
      return;
    }
    this.team.additionalDecks.push(deckToAdd);
    try {
      await teamService.linkDeck(this.team.id, this.deckIdToAdd);
      this.$emit('updatedDecks');
    } catch {
      this.team.additionalDecks = this.team.additionalDecks.filter((deck) => deck === deckToAdd);
    }
  }

  async onRemoveDeckClick(deckToRemove: Pack): Promise<void> {
    if (!this.team.id || !deckToRemove || !deckToRemove.id) {
      return;
    }
    this.team.additionalDecks = this.team.additionalDecks.filter((deck) => deck !== deckToRemove);
    try {
      await teamService.unlinkDeck(this.team.id, deckToRemove.id);
      this.$emit('updatedDecks');
    } catch {
      this.team.additionalDecks.push(deckToRemove);
    }
  }

  validateName(): void {
    this.nameError = !this.name.trim() ? 'Name should be filled' : '';
  }

  validateUserCount(): void {
    this.userCountError = !(this.userCount >= 0) ? 'User count should be 0 or more' : '';
  }

  async handleSubmit(): Promise<void> {
    if (!this.team.id) {
      throw new Error('Missing id property');
    }

    this.validateName();

    if (this.nameError) {
      return;
    }
    this.isSaving = true;
    const newTeam = {
      name: this.name,
      emailPattern: this.emailPattern,
      userCount: this.userCount,
      subscriptionExpire: this.subscriptionExpire,
    };
    try {
      await teamService.update(this.team.id, newTeam);
      this.$emit('updated');
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
