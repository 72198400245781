import authHeader from '@/helpers/auth-header';
import { Pack } from '@/api-domain/pack';
import http from '@/http-common';
import { PackWithCount } from '@/api-domain/packWithCount';

export class PackService {
  async create(pack: Pack): Promise<Pack> {
    const result = await http.post('/packs', pack, { headers: authHeader() });
    return result.data;
  }

  async getPack(id: string): Promise<Pack> {
    const result = await http.get(`/packs/${id}`, { headers: authHeader() });
    return result.data;
  }

  async getAll(): Promise<PackWithCount[]> {
    const result = await http.get('/packs', { headers: authHeader() });
    return result.data;
  }

  async update(id: string, pack: Pack): Promise<void> {
    return http.put(`/packs/${id}`, pack, { headers: authHeader() });
  }

  async delete(id: string): Promise<void> {
    return http.delete(`/packs/${id}`, { headers: authHeader() });
  }
}

export const packService = new PackService();
