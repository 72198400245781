import authHeader from '@/helpers/auth-header';
import { Team } from '@/api-domain/team';
import http from '@/http-common';

type TeamCreatePayload = Omit<Team, 'additionalDecks'>;
type TeamResponse = Team & { subscriptionExpire: string | null}

function stringToDate(s: string | null): Date | null {
  if (s === null) {
    return s;
  }
  return new Date(s);
}

export class TeamService {
  async create(team: TeamCreatePayload): Promise<Team> {
    const result = await http.post('/teams', team, { headers: authHeader() });
    return result.data;
  }

  async getAll(): Promise<Team[]> {
    const result = await http.get('/teams', { headers: authHeader() });
    const teams = result.data as TeamResponse[];
    return teams.map((item) => ({
      ...item,
      subscriptionExpire: stringToDate(item.subscriptionExpire),
    }));
  }

  async update(id: string, team: TeamCreatePayload): Promise<void> {
    return http.put(`/teams/${id}`, team, { headers: authHeader() });
  }

  async linkDeck(teamId: string, deckId: string): Promise<void> {
    return http.post(`/teams/${teamId}/restricted-deck`, { deckId }, { headers: authHeader() });
  }

  async unlinkDeck(teamId: string, deckId: string): Promise<void> {
    return http.delete(`/teams/${teamId}/restricted-deck/${deckId}`, { headers: authHeader() });
  }

  async delete(id: string): Promise<void> {
    return http.delete(`/teams/${id}`, { headers: authHeader() });
  }
}

export const teamService = new TeamService();
