
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Team } from '@/api-domain/team';
import { Pack } from '@/api-domain/pack';

@Options({
  components: {
  },
})
export default class EzPack extends Vue {
  @Prop({ required: true }) team!: Team;

  @Prop({ required: false }) isSelected = false;

  getDeckNames(decks: Pack[]): string {
    return decks.map((deck) => deck.name).join(', ');
  }

  formatDate(date: Date | null): string {
    if (date === null) {
      return 'Expiration not set';
    }
    return date.toDateString();
  }
}
