
import { Options, Vue } from 'vue-class-component';
import { teamService } from '@/services/team.service';

@Options({
  emits: ['created'],
})
export default class EzAddteam extends Vue {
  name = '';

  emailPattern = '';

  userCount = 0;

  subscriptionExpire: Date | null = null;

  showForm = false;

  isSaving = false;

  nameError = '';

  userCountError = '';

  expireError = '';

  emailPatternError = '';

  onAddClick(): void {
    this.showForm = true;
    setTimeout(() => {
      (this.$refs.teamNameRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateName(): void {
    this.nameError = !this.name.trim() ? 'Name should be filled' : '';
  }

  validateUserCount(): void {
    this.userCountError = !(this.userCount >= 0) ? 'User count should be 0 or more' : '';
  }

  validateExpire(): void {
    // this.expireError = this.subscriptionExpire
    //   ? 'Expire should be in YYYYY-MM-DD format or empty'
    //   : '';
  }

  clearData(): void {
    this.name = '';
    this.userCount = 0;
    this.subscriptionExpire = null;
  }

  async handleSubmit(): Promise<void> {
    this.validateName();
    this.validateUserCount();
    this.validateExpire();

    if (this.nameError) {
      return;
    }
    this.isSaving = true;
    const newTeam = {
      name: this.name,
      emailPattern: this.emailPattern,
      userCount: this.userCount,
      subscriptionExpire: this.subscriptionExpire,
    };

    try {
      const createdteam = await teamService.create(newTeam);
      this.$emit('created', createdteam);
      this.clearData();
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
