<template>
  <div class="team-delete">
    <div class="field has-addons">
      <button class="button is-primary mr-2" v-on:click="onDeleteClick()">Delete team</button>
    </div>
  </div>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
      <div class="modal-content has-text-centered">
        <div class="box content">
          <p>Do you really want to delete '{{ team.name }}'?</p>
          <div class="control">
            <button
              type="button"
              class="button is-danger"
              v-if="!isSaving"
              v-on:click="onSubmit()"
            >
              Delete
            </button>
            <button
              type="button"
              class="button"
              v-if="!isSaving"
              v-on:click="onCancelClick()"
            >
              Cancel
            </button>
          </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { teamService } from '@/services/team.service';
import { Team } from '@/api-domain/team';

@Options({
  emits: ['deleted'],
})
export default class EzDeleteTeam extends Vue {
  @Prop({ isRequired: true }) team!: Team;

  showForm = false;

  isSaving = false;

  onDeleteClick(): void {
    this.showForm = true;
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  async onSubmit(): Promise<void> {
    if (!this.team.id) {
      throw new Error('Missing id property');
    }

    this.isSaving = true;
    try {
      await teamService.delete(this.team.id);
      this.$emit('deleted', this.team.id);
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
</script>
