import authenticationService from '@/services/authentication.service';

export default function authHeader(): any {
  const loggedinUser = authenticationService.user;

  if (loggedinUser && loggedinUser.token) {
    return { Authorization: `Bearer ${loggedinUser.token}` };
  }

  return {};
}
