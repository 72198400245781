
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { teamService } from '@/services/team.service';
import { Team } from '@/api-domain/team';

@Options({
  emits: ['deleted'],
})
export default class EzDeleteTeam extends Vue {
  @Prop({ isRequired: true }) team!: Team;

  showForm = false;

  isSaving = false;

  onDeleteClick(): void {
    this.showForm = true;
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  async onSubmit(): Promise<void> {
    if (!this.team.id) {
      throw new Error('Missing id property');
    }

    this.isSaving = true;
    try {
      await teamService.delete(this.team.id);
      this.$emit('deleted', this.team.id);
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
