<template>
  <div class="team-add">
    <div class="field has-addons">
      <button
        class="button mr-2 is-primary is-light"
        v-on:click="onAddClick()"
      >
        Add new team
      </button>
    </div>
  </div>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <form @submit.prevent="handleSubmit">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  ref="teamNameRef"
                  placeholder="E.g. 'DIGIC' or 'Telekom'"
                  v-model="name"
                  :disabled="isSaving"
                />
                <p v-if="nameError" class="help is-danger">{{ nameError }}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">Email pattern</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="E.g. @digic.com"
                  v-model="emailPattern"
                  :disabled="isSaving"
                />
                <p v-if="emailPatternError" class="help is-danger">{{ emailPatternError }}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">User Count</label>
              <div class="control">
                <input
                  class="input"
                  type="number"
                  placeholder="E.g. 100"
                  v-model="userCount"
                  :disabled="isSaving"
                />
                <p v-if="userCountError" class="help is-danger">{{ userCountError }}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">Subscription Expire</label>
              <div class="control">
                <VueDatePicker
                  v-model="subscriptionExpire"
                  :teleport=true
                  :enable-time-picker="false">
                </VueDatePicker>
              </div>
            </div>
            <div class="field is-grouped">
              <div class="control">
                <button
                  type="submit"
                  class="button is-primary"
                  v-bind:class="isSaving ? 'is-loading' : ''"
                >
                  Add
                </button>
              </div>
              <div class="control">
                <button
                  type="button"
                  class="button"
                  v-if="!isSaving"
                  v-on:click="onCancelClick()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { teamService } from '@/services/team.service';

@Options({
  emits: ['created'],
})
export default class EzAddteam extends Vue {
  name = '';

  emailPattern = '';

  userCount = 0;

  subscriptionExpire: Date | null = null;

  showForm = false;

  isSaving = false;

  nameError = '';

  userCountError = '';

  expireError = '';

  emailPatternError = '';

  onAddClick(): void {
    this.showForm = true;
    setTimeout(() => {
      (this.$refs.teamNameRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateName(): void {
    this.nameError = !this.name.trim() ? 'Name should be filled' : '';
  }

  validateUserCount(): void {
    this.userCountError = !(this.userCount >= 0) ? 'User count should be 0 or more' : '';
  }

  validateExpire(): void {
    // this.expireError = this.subscriptionExpire
    //   ? 'Expire should be in YYYYY-MM-DD format or empty'
    //   : '';
  }

  clearData(): void {
    this.name = '';
    this.userCount = 0;
    this.subscriptionExpire = null;
  }

  async handleSubmit(): Promise<void> {
    this.validateName();
    this.validateUserCount();
    this.validateExpire();

    if (this.nameError) {
      return;
    }
    this.isSaving = true;
    const newTeam = {
      name: this.name,
      emailPattern: this.emailPattern,
      userCount: this.userCount,
      subscriptionExpire: this.subscriptionExpire,
    };

    try {
      const createdteam = await teamService.create(newTeam);
      this.$emit('created', createdteam);
      this.clearData();
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
</script>
