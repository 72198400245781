<template>
  <div class="team-edit">
    <div class="field has-addons">
      <button class="button is-primary mr-2" v-on:click="onEditClick()">Edit team</button>
    </div>
  </div>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <form @submit.prevent="handleSubmit">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="E.g. 'DIGIC' or 'Telekom'"
                  v-model="name"
                  :disabled="isSaving"
                />
                <p v-if="nameError" class="help is-danger">{{ nameError }}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">Email pattern</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  ref="teamNameRef"
                  placeholder="E.g. @digic.com"
                  v-model="emailPattern"
                  :disabled="isSaving"
                />
                <p v-if="emailPatternError" class="help is-danger">{{ emailPatternError }}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">User Count</label>
              <div class="control">
                <input
                  class="input"
                  type="number"
                  placeholder="E.g. 100"
                  v-model="userCount"
                  :disabled="isSaving"
                />
                <p v-if="userCountError" class="help is-danger">{{ userCountError }}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">Subscription Expire</label>
              <div class="control">
                <VueDatePicker
                  v-model="subscriptionExpire"
                  :teleport=true
                  :enable-time-picker="false">
                </VueDatePicker>
              </div>
            </div>
            <hr />
            <div class="field">
              <label class="label">Decks</label>
              <span class="linked-deck" v-for="deck in team.additionalDecks" :key="deck.id">
                {{ deck.name }}
                <button
                  class="linked-deck__remove"
                  type="button"
                  v-on:click="onRemoveDeckClick(deck)">
                  🗙
                </button>
              </span>
            </div>

            <div class="field has-addons">
              <div class="control is-expanded">
                <div class="select is-fullwidth">
                  <select v-model="deckIdToAdd" name="deck">
                    <option v-for="deck in restrictedDecks" :key="deck.id" :value="deck.id">
                      {{ deck.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="control">
                <button type="button" class="button is-primary" v-on:click="onAddDeckClick()">
                  Add
                </button>
              </div>
            </div>

            <div class="field is-grouped">
              <div class="control">
                <button
                  type="submit"
                  class="button is-primary"
                  v-bind:class="isSaving ? 'is-loading' : ''"
                >
                  Save
                </button>
              </div>
              <div class="control">
                <button
                  type="button"
                  class="button"
                  v-if="!isSaving"
                  v-on:click="onCancelClick()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { teamService } from '@/services/team.service';
import { Team } from '@/api-domain/team';
import { Pack } from '@/api-domain/pack';
import { packService } from '@/services/pack.service';

@Options({
  emits: ['updated', 'updatedDecks'],
})
export default class EzEditTeam extends Vue {
  @Prop({ isRequired: true }) team!: Team;

  name = '';

  emailPattern = '';

  userCount = 0;

  deckIdToAdd?: string;

  additionalDecks : string[] = [];

  subscriptionExpire: Date | null = null;

  restrictedDecks: Pack[] = [];

  showForm = false;

  isSaving = false;

  nameError = '';

  emailPatternError = '';

  userCountError = '';

  expireError = '';

  getDeckNames(): string[] {
    return this.team.additionalDecks.map((deck) => deck.name);
  }

  async onEditClick(): Promise<void> {
    this.restrictedDecks = (await packService.getAll()).filter((deck) => !deck.public);

    this.name = this.team.name;

    this.emailPattern = this.team.emailPattern;

    this.userCount = this.team.userCount;

    this.subscriptionExpire = this.team.subscriptionExpire;

    this.showForm = true;

    setTimeout(() => {
      (this.$refs.teamNameRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  async onAddDeckClick(): Promise<void> {
    if (!this.deckIdToAdd || !this.team.id) {
      return;
    }
    const deckToAdd = this.restrictedDecks.find((deck) => deck.id === this.deckIdToAdd);
    if (!deckToAdd) {
      return;
    }
    this.team.additionalDecks.push(deckToAdd);
    try {
      await teamService.linkDeck(this.team.id, this.deckIdToAdd);
      this.$emit('updatedDecks');
    } catch {
      this.team.additionalDecks = this.team.additionalDecks.filter((deck) => deck === deckToAdd);
    }
  }

  async onRemoveDeckClick(deckToRemove: Pack): Promise<void> {
    if (!this.team.id || !deckToRemove || !deckToRemove.id) {
      return;
    }
    this.team.additionalDecks = this.team.additionalDecks.filter((deck) => deck !== deckToRemove);
    try {
      await teamService.unlinkDeck(this.team.id, deckToRemove.id);
      this.$emit('updatedDecks');
    } catch {
      this.team.additionalDecks.push(deckToRemove);
    }
  }

  validateName(): void {
    this.nameError = !this.name.trim() ? 'Name should be filled' : '';
  }

  validateUserCount(): void {
    this.userCountError = !(this.userCount >= 0) ? 'User count should be 0 or more' : '';
  }

  async handleSubmit(): Promise<void> {
    if (!this.team.id) {
      throw new Error('Missing id property');
    }

    this.validateName();

    if (this.nameError) {
      return;
    }
    this.isSaving = true;
    const newTeam = {
      name: this.name,
      emailPattern: this.emailPattern,
      userCount: this.userCount,
      subscriptionExpire: this.subscriptionExpire,
    };
    try {
      await teamService.update(this.team.id, newTeam);
      this.$emit('updated');
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
</script>

<style scoped lang="scss">
.linked-deck {
  position: relative;
  cursor: default;

  &:after {
    content:" | ";
  }

  &__remove {
    display: none;
  }

  &:hover {
    background: rgba(255,0,0,0.1);
    .linked-deck__remove {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
    }
  }
}
</style>
