
import { Options, Vue } from 'vue-class-component';
import EzTeam from '@/components/teams/EzTeam.vue';
import EzAddTeam from '@/components/teams/EzAddTeam.vue';
import EzEditTeam from '@/components/teams/EzEditTeam.vue';
import EzDeleteTeam from '@/components/teams/EzDeleteTeam.vue';
import { Team } from '@/api-domain/team';
import { teamService } from '@/services/team.service';

@Options({
  components: {
    EzAddTeam,
    EzEditTeam,
    EzDeleteTeam,
    EzTeam,
  },
})
export default class Teams extends Vue {
  teams: Team[] = [];

  selectedTeam: Team | null = null;

  fetchError = false;

  isFetched = false;

  async fetchTeams(): Promise<void> {
    try {
      this.teams = await teamService.getAll();
      this.isFetched = true;
    } catch {
      this.fetchError = true;
      // TODO: how to handle? polling in every 10 seconds?
      // try again button?
    }
  }

  async created(): Promise<void> {
    await this.fetchTeams();
  }

  async onTeamCreated(): Promise<void> {
    await this.fetchTeams();
  }

  async onTeamUpdated(): Promise<void> {
    this.selectedTeam = null;
    // Should be fetched, because the ordering logic is on the server side
    await this.fetchTeams();
  }

  async onTeamDecksUpdated(): Promise<void> {
    // TODO: Only the affected team should be fethed
    await this.fetchTeams();
  }

  async onTeamDeleted(): Promise<void> {
    this.selectedTeam = null;
    await this.fetchTeams();
  }

  onTeamClick(team: Team): void {
    if (this.selectedTeam === team) {
      this.selectedTeam = null;
    } else {
      this.selectedTeam = team;
    }
  }
}
